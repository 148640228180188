import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlaneTop } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";

import { SubmitHandler } from "react-hook-form/dist/types/form";
import { useDashboardState } from "@src/app.state";
import useAuth from "@hooks/useAuth";
import useAlerts from "@hooks/useAlerts";
import dashboardAxios from "@src/api";
import { apiPaths } from "@src/paths";
import SusDialog from "@components/SusDialog/SusDialog";
import SusBox from "@components/SusBox";

interface IReOrderingDialogProps {
  options: ReOrderingDialogOptions;
}

interface ProductReorderingSpecs {
  posId?: string;
  name?: string;
  amount?: string;
  productQuality?: string;
  productSize?: string;
  packaging?: string;
}

export type ReOrderingDialogOptions = {
  products: ProductReorderingSpecs[];
  incoterms?: string;
  incotermsCity?: string;
  contractId?: number;
  contractDistributorReference?: string;
};

export type RequestQuoteDialogOptions = {
  products: ProductReorderingSpecs[];
  incoterms?: string;
  incotermsCity?: string;
  contractId?: number;
  contractDistributorReference?: string;
};

interface FormData {
  product: string;
  productQuality: string;
  productSize: string;
  amount: string;
  packaging: string;
  contractDistributorReference: string;
  incoterms: string;
  incotermsCity: string;
  additionalInformation: string;
}

const ReOrderingDialog: React.FC<IReOrderingDialogProps> = ({ options }) => {
  const { currentCompany, currentDialog, setCurrentDialog } = useDashboardState();
  const { user } = useAuth();
  const { reset, register, handleSubmit } = useForm<FormData>();
  const { notifySuccess, notifyError } = useAlerts();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormData | undefined>();

  const onSubmit: SubmitHandler<FormData> = async (data, event) => {
    event?.preventDefault();
    setLoading(true);
    dashboardAxios
      .post(apiPaths.reorderRequest, {
        ...data,
        salesRepName: currentCompany?.companySalesRepName,
        salesRepEmail: currentCompany?.companySalesRepEmail,
        requesterName: `${user?.firstName} ${user?.lastName}`,
        requesterEmail: user?.email,
        company: currentCompany?.companyName,
      })
      .then(() => {
        setErrors(undefined);
        notifySuccess({
          title: "Success",
          content: "Support request sent.",
          autoHideDuration: 5000,
        });
        setCurrentDialog(null);
      })
      .catch((error) => {
        setErrors(error?.response?.data?.errors);
        notifyError({
          title: "Error",
          content: "Something went wrong. Please check your input.",
          autoHideDuration: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!options) {
      return;
    }
    reset({
      product: selectedProduct.name,
      productQuality: selectedProduct.productQuality,
      productSize: selectedProduct.productSize,
      amount: selectedProduct.amount,
      packaging: selectedProduct.packaging,
      contractDistributorReference: options.contractDistributorReference,
      incoterms: options.incoterms,
      incotermsCity: options.incotermsCity,
    });
  }, [options]);

  const [selectedProduct, setSelectedProduct] = useState<ProductReorderingSpecs>(
    options.products[0]
  );
  const handleProductChange = (event: any) => {
    const newSelectedProduct = options.products.filter(
      (product) => product.posId === event.target.value
    )[0];
    reset({
      product: newSelectedProduct.name,
      productQuality: newSelectedProduct.productQuality,
      productSize: newSelectedProduct.productSize,
      amount: newSelectedProduct.amount,
      packaging: newSelectedProduct.packaging,
    });
    setSelectedProduct(newSelectedProduct);
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }
    setCurrentDialog(null);
  };

  return (
    <SusDialog
      title={`Quote request`}
      open={currentDialog.type === "ReOrderingDialog"}
      onClose={handleClose}
      sx={{ "& .MuiPaper-root": { width: "700px" } }}
      actions={() => (
        <>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            size="medium"
            sx={{
              lineHeight: "1",
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: "",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            <SusBox mr="0.5rem" color="white" display="inlineFlex">
              <FontAwesomeIcon icon={faPaperPlaneTop} size="xs" />
            </SusBox>
            Send
          </LoadingButton>
        </>
      )}
    >
      <SusBox p={2}>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Product Name</InputLabel>
          <Select
            labelId="status-select-label"
            id="product-select-input"
            label="Product Name"
            onChange={(e) => handleProductChange(e)}
            value={selectedProduct.posId}
          >
            {options.products.map((product) => (
              <MenuItem key={product.name} value={product.posId}>
                <SusBox>
                  <SusBox display="inline-flex" p="7px">
                    {product.name}
                  </SusBox>{" "}
                </SusBox>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Product Quality</InputLabel>
          <OutlinedInput
            label="Product Quality"
            {...register("productQuality")}
            error={!!errors && "productQuality" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Product Size</InputLabel>
          <OutlinedInput
            label="Product Size"
            {...register("productSize")}
            error={!!errors && "productSize" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Contract Amount</InputLabel>
          <OutlinedInput
            label="Contract Amount"
            {...register("amount")}
            error={!!errors && "amount" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Packaging</InputLabel>
          <OutlinedInput
            label="Packaging"
            {...register("packaging")}
            error={!!errors && "packaging" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Incoterms</InputLabel>
          <OutlinedInput
            label="Incoterms"
            {...register("incoterms")}
            error={!!errors && "incoterms" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Incoterms Location</InputLabel>
          <OutlinedInput
            label="Incoterms Location"
            {...register("incotermsCity")}
            error={!!errors && "incotermsCity" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Additional Information</InputLabel>
          <OutlinedInput
            label="Additional Information"
            {...register("additionalInformation")}
            error={!!errors && "additionalInformation" in errors}
            fullWidth
            multiline
            rows={2}
          />
          {errors && "Additional Information" in errors && (
            <FormHelperText id="standard-weight-helper-text">
              {errors.additionalInformation}
            </FormHelperText>
          )}
        </FormControl>
      </SusBox>
    </SusDialog>
  );
};

export default ReOrderingDialog;
