import React from "react";
import { WidgetProps, WidgetSpecs } from "@src/features/control-room";
import { useDashboardState } from "@src/app.state";
import NewContractDialog from "@components/NewContractDialog/NewContractDialog";
import NewContractPositionDialog from "@components/NewContractPositionDialog/NewContractPositionDialog";
import SupportContactDialog from "@components/SupportContactDialog/SupportContactDialog";
import ReOrderingDialog from "@components/ReOrderingDialog/ReOrderingDialog";
import SusDialog from "@components/SusDialog/SusDialog";
import SusDocumentView from "@components/SusDocumentView/SusDocumentView";
import AddControlRoomWidgetDialog from "@components/AddControlRoomWidgetDialog/AddControlRoomWidgetDialog";
import AddControlRoomWidgetDetailsDialog from "@components/AddControlRoomWidgetDialog/AddControlRoomWidgetDetailsDialog";
import { EditUserDialog } from "@components/Dialogs/styles";
import SusTabBox from "@components/SusTabBox/SusTabBox";
import UserProfile from "@src/views/settings/tabPages/UserProfile";
import AboutDialog from "@components/AboutDialog/AboutDialog";
import SetupDialog from "@components/SetupDialog/SetupDialog";
import ServiceConsentDialog from "@components/ServiceConsentDialog/ServiceConsentDialog";
import RequestQuoteDialog from "@components/RequestQuoteDialog/RequestQuoteDialog";

export type ShowDocumentDialogOptions = {
  fileName?: string;
  fileUrl?: string;
  onClose?: (event: any, reason: string) => void;
};

export type AddControlRoomWidgetDialogOptions = {
  onAddWidget: (specs: WidgetSpecs, propValues?: WidgetProps) => void;
  onResetToDefault: () => void;
};

export type SupportContactDialogOptions = {
  fistName: string;
};

export type SalesRepDialogOptions = {
  fistName: string;
};

export type AddControlRoomWidgetDetailsDialogOptions = {
  specs: WidgetSpecs;
  onAddWidget: (specs: WidgetSpecs, propValues?: WidgetProps) => void;
};

export type EditUserDialogOptions = {
  userId: number;
  firstName: string;
  lastName: string;
  onClose?: (event: any, reason: string) => void;
};

const Dialogs: React.FC = () => {
  const { currentDialog } = useDashboardState();

  switch (currentDialog.type) {
    case "NewContract":
      return <NewContractDialog />;
    case "NewContractPosition":
      return <NewContractPositionDialog />;
    case "SupportContactDialog":
      return <SupportContactDialog />;
    case "ReOrderingDialog":
      return <ReOrderingDialog options={currentDialog.options} />;
    case "RequestQuoteDialog":
      return <RequestQuoteDialog options={currentDialog.options} />;
    case "ShowDocument":
      return currentDialog.options.fileUrl ? (
        <SusDialog
          title={currentDialog.options.fileName || ""}
          onClose={currentDialog.options.onClose || (() => null)}
          open={currentDialog.type === "ShowDocument"}
          actions={() => <></>}
        >
          <SusDocumentView url={currentDialog.options.fileUrl} />
        </SusDialog>
      ) : null;
    case "AddControlRoomWidget":
      return (
        <AddControlRoomWidgetDialog
          onAddWidget={currentDialog.options.onAddWidget}
          onResetToDefault={currentDialog.options.onResetToDefault}
        />
      );
    case "AddControlRoomWidgetDetails":
      return (
        <AddControlRoomWidgetDetailsDialog
          specs={currentDialog.options.specs}
          onAddWidget={currentDialog.options.onAddWidget}
        />
      );
    case "EditUser":
      return (
        <SusDialog
          title={`Edit User ${currentDialog.options.firstName} ${currentDialog.options.lastName}`}
          open={currentDialog.type === "EditUser"}
          onClose={currentDialog.options.onClose || (() => null)}
          sx={EditUserDialog}
        >
          <SusTabBox
            data={[
              {
                tabTitle: "User details",
                tabContent: (
                  <UserProfile
                    userId={currentDialog.options.userId}
                    onSubmit={() =>
                      (currentDialog.options.onClose &&
                        currentDialog.options.onClose(null, "Submit")) ||
                      (() => null)
                    }
                    hidePrivacySettings
                  />
                ),
              },
            ]}
          />
        </SusDialog>
      );
    case "AboutDialog":
      return <AboutDialog />;
    case "SetupDialog":
      return <SetupDialog />;
    case "ServiceConsentDialog":
      return <ServiceConsentDialog />;
    default:
      return null;
  }
};

export default Dialogs;
